@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.work {
    width: 100%;
    height: 80vh;
    position: relative;
    background: #00000000;
  }
  
  .work:before {
    content: '';
    position: absolute;
    background: url('../../assets/work_hero.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.work .work-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.work h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;


}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.work h2 {
    font-size: 1.5rem;
}

.work h1 {
    font-size: 2rem;
}
}