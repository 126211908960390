@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.mywork {
    width: 100%;
    position: relative;
}

.mywork .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.mywork-container {
    text-align: center;
    padding: 25px;
}

.mywork-container p {
        padding: 1rem 0;
        font-family: 'Montserrat', sans-serif;
    }

.mywork h1 {
    padding: 1rem;
    font-family: 'Montserrat', sans-serif;
}


.mywork-thumbnail {
    width: 400px;
}

.mywork-thumbnail img {
    width: 100%;
}






@media  screen and (max-width:940px) {



}

@media  screen and (max-width:600px) {
    .software-thumbnail {
        width: 80px;
    }
}





