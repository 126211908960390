@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.footer {
    width: 100%;
    height: auto;
    position: relative;
    background: #1b1b1b;
    /* background: linear-gradient(0deg, rgba(154,34,195,0.8785889355742297) 0%, rgba(130,45,253,1) 100%);    padding: 3rem 0; */
}

/* .footer:before {
    content:'';
    position: absolute;
    background: url('../../assets/footer-bg.jpg')no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
} */

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0 1rem 0;
}

.logo-footer h1 {
    font-size: 7rem;
    text-align: left;
    color: #c9860a;
    font-family: "Sullington";
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
}

.footer .top .footer-icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
}




.footer h3 {
    color: white;
    font-size: 1rem;
    text-align: center;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
}

.col {
    width: 300px;
}

.col-container .col p {
    cursor: pointer;
    text-align: center;
}

.footer h3 {
    margin: 1rem 0 2rem 0;
    text-transform: uppercase;
}

.footer .license p {
    color: white;
 
}

.footer .license img {
    margin: 1rem 0;
}


.footer .license a:hover {
    color: rgb(8, 138, 245);
}

.footer p {
    font-family: 'Montserrat', sans-serif;
    margin: .5rem 0;
    color: white;
    font-weight: 500;
    text-align: left;
}

.footer form {
    width: 100%;
    margin-bottom: .6rem;
    position: relative;
}

.footer form input {
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer form input:focus {
    color: #333;
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-dark);
}

.footer form .mail-icon {
    position: absolute;
    font-size: 1.4rem;
    margin: 1rem;
    right: .2rem;
    top: 3.8rem;
    color: #aaa;
    cursor: pointer;
}

.footer form .social-icon {
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}


.copyright p {
    color: white;
    text-transform: uppercase;
}

@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }

    .footer .col-container {
        grid-template-columns: 2fr 1fr;
    }

    .footer form {
        grid-column: 1 / span 2;
    }

    .footer h1, .footer h2, .footer h3 {
        text-align: left;
    }

    .license {
        width: 100%;
        text-align: center;
    }

.col h3, .col p {
    text-align: center;
}

    .license h3 {
        text-align: center;
    }

    .license p {
        text-align: center;
    }

}


