@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');


.bg {
  background: #ffffff;
}

.main-cards-header {
  padding:  1rem;
}

.main-cards-header p {
  font-size: 14px;
  color: rgb(48, 45, 45);
  text-align: center;
}

.main-cards-header h1 {
  text-align: center;
  font-family: "Sullington";
  font-size: 5rem;
  padding: 1rem;
  color: #c9860a;
  letter-spacing: 3px;
}


/* CARDS */

.cards-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 3rem;
}


.card-caption h1 {
  text-align: center;
  color: rgba(36, 36, 33, 0.733);
  font-size: 1rem;
  font-weight: bold;
  padding: .5rem;

}

.card-iframe iframe {
  border-radius: 1rem;
}

@media screen and (max-width:700px) {


  .card-iframe {
    margin: 2rem 0;
  }

  .card-iframe iframe {
    width: 100%;
    height: 100%;
  }

  .card-caption h1 {
    padding: .2rem 0;
    font-size: .8rem;
  }
  
}








