/* ImageGallery.css */
.gallery {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .gallery-image {
    width: 20%;
    object-fit: cover;
    cursor: pointer;
  }

  .galleryHeader {
    text-align: center;
    padding: 2rem 1rem;
  }

  .galleryHeader h1 {
    text-align: center;
    font-family: "Sullington";
    font-size: 5rem;
    padding: 1rem;
    color: #c9860a;
    letter-spacing: 3px;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }
  

  @media screen and (max-width:940px) {
    .gallery {
        flex-wrap: wrap;
      }

      .gallery-image {
        width: 50%;
      }
    
  }