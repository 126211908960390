@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');


.hero {
  width: 100%;
  height: 100dvh;
  position: relative;
  display: flex;
  justify-content: center;
  background: linear-gradient(to left, #000000, #44424241), url('../../assets/hero.webp') no-repeat center top/cover;

}

.hero .hero-container .content {
    width: max-content;
    text-align: center;
    bottom: 30%;
    right: 10%;
    position: absolute;
}

.hero h1 {

    font-size: 15rem;
    text-align: left;
    color: #c9860a;
    font-family: 'Sullington', serif;
    letter-spacing: 5px;
}

.hero h2 {
    font-size: 1.5rem;
    color: white;
    text-align: left;
    font-weight: normal;
    text-transform: uppercase;
}

.hero-container p {
    font-size: 18px;
    color: white;
    text-align: left;
}

.hero .hero-btn button {
    cursor: pointer;
}

.socials {
    padding: .5rem 0;
    display: flex;
    justify-content: left;
    gap: .5rem;
}

.socials p {
    font-size: 1.5rem;
    color: #c9860a;
}





/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .hero .hero-container .content{
    width: 100%;
    bottom: 20%;
    left: 0%;
}

.socials {
    justify-content: center;
}


    .hero h2 {
        text-align: center;
    }

    .hero h1 {
    font-size: 10rem;
    text-align: center;

    }

    .hero p {
        text-align: center;

    }

}




