@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.resume {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.resume:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.03;
}

.resume-content {
  padding:1rem;
}



.resume-header {
  padding: 1rem 0;
}

.resume-header h1 {
  text-align: center;
  font-family: "Sullington";
  font-size: 5rem;
  padding: 1rem;
  color: #c9860a;
  letter-spacing: 3px;
}

.resume-header h2 {
  font-size: 1rem;
  padding: .3rem 0;
}


.resume-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-top: 2rem;
}

.resume-left {
  width: 50%;
  margin: auto 0;
}

.resume-left h1 {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
}

.resume-left h2 {
  font-size: .8rem;
  padding: .3rem 0;
  font-weight: normal;
  text-transform: uppercase;
}

.resume-left ul {
  font-size: .9rem;
  list-style-type: disc;
  padding: 0 1rem;
}

.workExperience {
  padding: 1rem 0;
}

.resume-right {
  width: 400px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.resume-right img {
  width: 100%;
  border-radius: 15px;
  padding: .2rem;
}

.resume-more {
  padding: 1rem;
}
.resume-more h1 {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;

}

.resume-more h2 {
  font-size: .8rem;
  padding: .3rem 0;
  font-weight: normal;
  text-transform: uppercase;}

.resume-more ul {
  font-size: .9rem;
  list-style-type: disc;
  padding: 0 1rem;
}


/* MEDIA QUERIES */

@media screen and (max-width:700px) {

  .resume-flex {
    flex-wrap: wrap-reverse;
  }
  
  .resume-left {
    width: 100%;
    margin: auto;
  }
  
  .resume-right {
    width: 100%;
    margin: auto;
  }


  
  }



