@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.software {
    width: 100%;
    position: relative;
}

.software .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.software-container {
    text-align: center;
    padding: 25px;
}

.software-container p {
        padding: 1rem 0;
        font-family: 'Montserrat', sans-serif;
    }

.software h1 {
    text-align: center;
    font-family: "Sullington";
    font-size: 5rem;
    padding: 1rem;
    color: #c9860a;
    letter-spacing: 3px;
}


.software-thumbnail {
    width: 100px;
}

.software-thumbnail img {
    width: 100%;
}



@media  screen and (max-width:600px) {
    .software-thumbnail {
        width: 80px;
    }
}





